import {
  Add,
  EditOutlined,
  ErrorOutline,
  HighlightOff,
  InfoOutlined,
  Remove,
  WarningAmber,
} from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  Slider,
  // Slider,
  Step,
  StepButton,
  Stepper,
  TextField,
  Tooltip,
  // Tooltip,
  Typography,
} from "@mui/material";
// import InputAdornment from "@mui/material";
import { queryClient } from "index";
import moment from "moment";
import { useEffect, useState } from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { authorizedFetch, setLoader, snackbar, titleCase } from "utils";
import { BOLT_URL, PAYMENTS_URL } from "utils/constants";
import SetAvailability from "../SetAvailability";
import PhoneTextField from "components/PhoneTextField";
import PaymentDialog from "views/Charger/Users/PaymentDialog";
import { useQuery } from "react-query";
import getSymbolFromCurrency from "currency-symbol-map";
// import ChargerHealth from "../Overview/ChargerHealth";

const inputObj: any = {
  maintainerName: "",
  maintainerPhone: "",

  // Station info
  address: "",
  city: "",
  pincode: "",
  district: "",
  state: "",
  country: "",
  latitude: "",
  longitude: "",

  // Payment details
  ownerCostPerkWh: "",
  costPerkWh: "",
  platformFee: "",
  platformFeeDiscount: "",
  settlementCycle: "",
  paymentMethodId: "",

  autoCutoff: 0,
  powerOutput: 0,
};

const EditChargerDialog = ({
  open,
  handleClose,
  count,
  data,
  configData,
  closeDrawer,
}: any) => {
  const userId = data?.charger?.registrantId;
  const currency = data?.paymentDetails?.currency;
  const [step, setStep] = useState<number>(0);
  const steps = ["Station Info", "Availability", "Specification", "Finish"];

  const [usageType, setUsageType] = useState("");
  const [twentyFourSeven, setTwentyFourSeven] = useState(false);
  const [days, setDays] = useState<any>([]);

  const [input, setInput] = useState({ ...inputObj });

  const [paymentDialog, setPaymentDialog] = useState<any>({
    open: false,
    data: null,
  });

  const {
    // uid,
    chargerName,
    maintainerName,
    maintainerPhone,

    // stationName,
    address,
    city,
    pincode,
    district,
    state,
    latitude,
    longitude,
    country,

    ownerCostPerkWh,
    costPerkWh,
    platformFee,
    platformFeeDiscount,
    settlementCycle,
    paymentMethodId,

    autoCutoff,
    powerOutput,
  } = input;

  const uid = data.charger.chargerId;

  const isPaid = ["PUBLIC_PAID", "RESTRICTED_PAID"].includes(usageType);

  const { isLoading: paymentMethodsLoading, data: paymentMethodsData } =
    useQuery(
      ["getPaymentDetails", userId],
      () =>
        authorizedFetch(
          `${PAYMENTS_URL}/v1/payments/paymentmeta/default/getAll?userId=${userId}`,
        ),
      {
        enabled: !!userId && isPaid,
      },
    );

  useEffect(() => {
    if (!open) return;
    setStep(count);
  }, [open, count]);

  useEffect(() => {
    if (!open) return;
    if (data) {
      setUsageType(data.charger.usageType);

      setInput({
        uid: data.charger.chargerId,
        name: data.charger.chargerName,
        chargerName: data.charger.chargerName,
        maintainerName: data.incharge.name,
        maintainerPhone: data.incharge.phoneNumber,
        // stationName: data.station.stationName,
        address: data.station.address,
        city: data.station.city,
        pincode: data.station.postalCode,
        district: data.station.district,
        state: data.station.state,
        country: data.station.country,
        latitude: data.station.location.latitude,
        longitude: data.station.location.longitude,

        ownerCostPerkWh: String(data.paymentDetails.ownerCostPerkWh),
        costPerkWh: String(data.paymentDetails.costPerkWh),
        platformFee: String(data.paymentDetails.platformFee),
        platformFeeDiscount: String(data.paymentDetails.platformFeeDiscount),
        settlementCycle: data.paymentDetails.settlementCycle || "WEEKLY",
        paymentMethodId: data.paymentDetails.paymentMethodId || "NA",

        autoCutoff:
          configData?.autoCutOffTime?.value ||
          data.specification.autoCutOff.value ||
          0,
        powerOutput:
          configData?.powerOutput?.value ||
          data.specification.powerOutput.value ||
          0,
      });
      setTwentyFourSeven(data.availability.twentyFourSeven);
      setDays(data.availability.days);
    } else {
      setInput({ ...inputObj });
      setTwentyFourSeven(false);
      setDays([]);
    }
  }, [data, configData, open]);

  function formatDays() {
    let data = [...days];
    return data.map((el: any) => ({
      day: el.day,
      fromTime: moment(el.fromTime).format("HH:mm"),
      toTime: moment(el.toTime).format("HH:mm"),
    }));
  }

  function handleNext() {
    if (step === steps.length - 1) {
      onSave();
    } else setStep(step + 1);
  }
  function handleBack() {
    setStep(step - 1);
  }

  function isPriceValid(price: any) {
    return currency === "INR"
      ? parseFloat(price) >= 1 && parseFloat(price) <= 30
      : true;
  }

  function isDisabled() {
    if (
      [
        chargerName,

        maintainerName,
        maintainerPhone,
        address,
        city,
        pincode,
        district,
        state,
        latitude,
        longitude,
        country,

        usageType,

        days,
        twentyFourSeven,
      ].includes("")
    )
      return true;

    if (isPaid) {
      if (
        [costPerkWh, ownerCostPerkWh].some((el) => !isPriceValid(el)) ||
        parseFloat(ownerCostPerkWh) >= parseFloat(costPerkWh) ||
        [platformFee, platformFeeDiscount].includes("") ||
        parseFloat(platformFee) < 0 ||
        parseFloat(platformFee) > 50 ||
        parseFloat(platformFeeDiscount) < 0 ||
        parseFloat(platformFeeDiscount) > 100 ||
        !paymentMethodId ||
        paymentMethodId === "NA"
      )
        return true;
    }

    return false;
  }

  function isComplete(step: number) {
    switch (step) {
      case 0:
        return (
          ![
            chargerName,
            maintainerName,
            maintainerPhone,
            address,
            city,
            pincode,
            district,
            state,
            latitude,
            longitude,
            country,
            usageType,
            ownerCostPerkWh,
            costPerkWh,
          ].includes("") &&
          (isPaid ? !!paymentMethodId && paymentMethodId !== "NA" : true)
        );
      case 1:
        return ![days, twentyFourSeven].includes("");
      case 2:
        return ![autoCutoff, powerOutput].includes("");
      default:
        break;
    }
  }

  function returnTime(day: number) {
    return twentyFourSeven
      ? "All Day"
      : days.some((el: any) => el.day === day) === false
        ? "Unavailable"
        : days
            .filter((el: any) => el.day === day)
            .map((el: any) => (
              <div>
                {moment(el.fromTime, ["ddd MMM DD YYYY HH:mm:ss"]).format(
                  "h:mm A",
                ) +
                  "-" +
                  moment(el.toTime, ["ddd MMM DD YYYY HH:mm:ss"]).format(
                    "h:mm A",
                  )}
              </div>
            ));
  }

  function onSave() {
    setLoader(true);
    const url = `${BOLT_URL}/v2/charger/${uid}/update`;
    authorizedFetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        stage: "prod",
      },
      body: {
        availability: {
          days: formatDays(),
          twentyFourSeven: twentyFourSeven,
        },
        charger: {
          chargerName: chargerName,
          usageType: usageType,
        },
        station: {
          address: address,
          city: city,
          postalCode: pincode,
          district: district,
          state: state,
          country: country,
          location: {
            latitude: latitude,
            longitude: longitude,
          },
        },
        incharge: {
          name: maintainerName,
          phoneNumber: maintainerPhone,
        },
        paymentDetails: {
          tariffMode: "KWH",
          ownerCostPerkWh: parseFloat(ownerCostPerkWh),
          costPerkWh: parseFloat(costPerkWh),
          baseAmount: 0,
          chargePerHour: 0,
          ...(settlementCycle ? { settlementCycle: settlementCycle } : {}),
          paymentMethodId: isPaid ? paymentMethodId : "NA",
          platformFee: parseFloat(platformFee),
          platformFeeDiscount: parseFloat(platformFeeDiscount),
        },
        specification: {
          autoCutOff: autoCutoff,
          powerOutput: powerOutput,
        },
      },
    })
      .then((res) => {
        setLoader(false);
        if (res.status === 200) {
          ["getCharger", "getChargers", "getAllStats"].forEach((el) => {
            queryClient.resetQueries(el);
          });
          snackbar.success(`Charger updated`);
          handleClose();
          // closeDrawer();
        } else {
          snackbar.error(
            `Error${res.message ? ": " + res.message : " updating charger"}`,
          );
        }
      })
      .catch((err) => {
        setLoader(false);
        snackbar.error(`Error updating charger`);
      });
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { maxWidth: 800, width: 1 } }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          Edit Charger
          <Divider flexItem orientation="vertical" sx={{ mx: 1.5 }} />
          <Typography color="text.secondary">
            {data.charger.chargerId}
          </Typography>
        </Box>
        <IconButton
          children={<HighlightOff />}
          color="inherit"
          onClick={handleClose}
          sx={{ transform: "translate(8px, -8px)" }}
        />
      </DialogTitle>
      <DialogContent sx={{ pb: "16px !important" }}>
        <Stepper
          sx={{ my: 4, mx: "auto", maxWidth: 534 }}
          activeStep={step}
          nonLinear
          alternativeLabel
        >
          {steps.map((label, i) => (
            <Step key={i}>
              <StepButton onClick={() => setStep(i)}>{label}</StepButton>
            </Step>
          ))}
        </Stepper>
        {/* {step === 0 && (
          <Box
            sx={{
              maxWidth: 280,
              mx: "auto",
              py: 2,
              display: "grid",
              gridTemplateColumns: "1fr",
              gap: 3,
            }}
          >
            <Box>
              <Typography className="label">UID</Typography>
              <TextField
                fullWidth
                disabled
                size="small"
                placeholder="UID"
                value={uid}
                onChange={(e: any) => {
                  setInput({ ...input, uid: e.target.value });
                }}
              />
            </Box>
            <Box>
              <Typography className="label">Name</Typography>
              <TextField
                fullWidth
                size="small"
                placeholder="Name"
                value={name}
                onChange={(e: any) => {
                  setInput({ ...input, name: e.target.value });
                }}
              />
            </Box>
          </Box>
        )} */}
        {step === 0 && (
          <Box
            sx={{
              maxWidth: 560,
              mx: "auto",
              display: "grid",
              gridTemplateColumns: { sm: "1fr 1fr" },
              gap: 3,
            }}
          >
            <Box
              sx={{
                gridColumn: { sm: "span 2" },
              }}
            >
              <Typography className="label">Usage Type</Typography>
              <RadioGroup
                row
                sx={{
                  display: "grid",
                  gridTemplateColumns: {
                    xs: "1fr 1fr",
                    sm: "repeat(3, 1fr)",
                  },
                }}
                value={usageType}
                onChange={(e: any) => setUsageType(e.target.value)}
              >
                {[
                  "PUBLIC_PAID",
                  "PUBLIC_FREE",
                  ...(!data?.isRupeeCharger ? ["PRIVATE"] : []),
                  "RESTRICTED_PAID",
                  "RESTRICTED_FREE",
                ].map((el, i) => (
                  <FormControlLabel
                    key={i}
                    value={el}
                    control={<Radio />}
                    label={el.split("_").map(titleCase).join(" ")}
                  />
                ))}
              </RadioGroup>
            </Box>
            <Box gridColumn={{ sm: "span 2" }}>
              <Typography className="label">Charger Name</Typography>
              <TextField
                fullWidth
                size="small"
                placeholder="Charger Name"
                value={chargerName}
                onChange={(e: any) => {
                  setInput({
                    ...input,
                    chargerName: e.target.value,
                  });
                }}
              />
            </Box>
            <Box>
              <Typography className="label">Maintainer Name</Typography>
              <TextField
                fullWidth
                size="small"
                placeholder="Maintainer Name"
                value={maintainerName}
                onChange={(e: any) => {
                  setInput({ ...input, maintainerName: e.target.value });
                }}
              />
            </Box>
            <Box>
              <Typography className="label">Maintainer Phone</Typography>
              <PhoneInput
                placeholder="Maintainer Phone"
                value={maintainerPhone}
                onChange={(value) =>
                  setInput({ ...input, maintainerPhone: value || "" })
                }
                defaultCountry="IN"
                international
                limitMaxLength
                inputComponent={PhoneTextField}
                error={
                  !!maintainerPhone && !isValidPhoneNumber(maintainerPhone)
                }
              />
            </Box>

            <Box sx={{ gridColumn: { sm: "span 2" } }}>
              <Typography className="label">Station Address</Typography>
              <Box
                sx={{
                  maxWidth: 560,
                  mx: "auto",
                  display: "grid",
                  gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
                  gap: 2,
                  mt: 2,
                }}
              >
                <Box sx={{ gridColumn: { sm: "span 2" } }}>
                  <Typography fontSize={14} mb={1.5}>
                    Plot No., Street Name, Landmark
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="Plot No., Street Name, Landmark"
                    value={address}
                    onChange={(e: any) => {
                      setInput({
                        ...input,
                        address: e.target.value,
                      });
                    }}
                  />
                </Box>
                <Box>
                  <Typography fontSize={14} mb={1}>
                    City
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="City"
                    value={city}
                    onChange={(e: any) => {
                      if (/^[a-zA-Z]{0,20}$/.test(e.target.value))
                        setInput({
                          ...input,
                          city: e.target.value,
                        });
                    }}
                  />
                </Box>
                <Box>
                  <Typography fontSize={14} mb={1}>
                    Pincode
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="Pincode"
                    value={pincode}
                    onChange={(e: any) => {
                      if (/^[0-9]{0,6}$/.test(e.target.value))
                        setInput({
                          ...input,
                          pincode: e.target.value,
                        });
                    }}
                  />
                </Box>
                <Box>
                  <Typography fontSize={14} mb={1}>
                    District
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="District"
                    value={district}
                    onChange={(e: any) => {
                      if (/^[a-zA-Z\s]{0,20}$/.test(e.target.value))
                        setInput({
                          ...input,
                          district: e.target.value,
                        });
                    }}
                  />
                </Box>
                <Box>
                  <Typography fontSize={14} mb={1}>
                    State
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="State"
                    value={state}
                    onChange={(e: any) => {
                      if (/^[a-zA-Z]{0,20}$/.test(e.target.value))
                        setInput({
                          ...input,
                          state: e.target.value,
                        });
                    }}
                  />
                </Box>
                <Box>
                  <Typography fontSize={14} mb={1}>
                    Latitude
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="Latitude"
                    value={latitude}
                    onChange={(e: any) => {
                      if (/^[-]?[0-9.]{0,11}$/.test(e.target.value))
                        setInput({
                          ...input,
                          latitude: e.target.value,
                        });
                    }}
                  />
                </Box>
                <Box>
                  <Typography fontSize={14} mb={1}>
                    Longitude
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="Longitude"
                    value={longitude}
                    onChange={(e: any) => {
                      if (/^[-]?[0-9.]{0,11}$/.test(e.target.value))
                        setInput({
                          ...input,
                          longitude: e.target.value,
                        });
                    }}
                  />
                </Box>
                <Box sx={{ gridColumn: { sm: "span 2" } }}>
                  <Typography fontSize={14} mb={1}>
                    Country
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="Country"
                    value={country}
                    onChange={(e: any) => {
                      if (/^[a-zA-Z]{0,20}$/.test(e.target.value))
                        setInput({
                          ...input,
                          country: e.target.value,
                        });
                    }}
                  />
                </Box>
              </Box>
            </Box>

            <Box sx={{ gridColumn: "1/-1" }}>
              <Collapse in={isPaid}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: { sm: "1fr 1fr" },
                    gap: 3,
                  }}
                >
                  <Box sx={{ mt: 1, gridColumn: "1/-1" }}>
                    <Typography variant="overline">Payment Details</Typography>
                    <Divider />
                  </Box>
                  {[
                    {
                      key: "ownerCostPerkWh",
                      label: "Energy Tariff",
                    },
                    {
                      key: "costPerkWh",
                      label: "Cost Per Unit (kWh)",
                    },
                  ].map(({ key, label }, i) => (
                    <Box key={i}>
                      <Typography className="label">{label}</Typography>
                      <PriceInput
                        {...{
                          objKey: key,
                          label,
                          input,
                          setInput,
                          isPriceValid,
                          currency,
                        }}
                      />
                    </Box>
                  ))}
                  <Alert severity="info" sx={{ gridColumn: "1/-1", mt: -1.5 }}>
                    <Typography variant="body2">
                      <b>Cost Per Unit</b> should be greater than the{" "}
                      <b>Energy Tariff</b>
                    </Typography>
                  </Alert>
                  <Box>
                    <Typography className="label">Platform Fee</Typography>
                    <PriceInput
                      {...{
                        objKey: "platformFee",
                        label: "Platform Fee",
                        input,
                        setInput,
                        isPriceValid: (price: any) =>
                          parseFloat(price) >= 0 && parseFloat(price) <= 50,
                        currency,
                        min: 0,
                        max: 50,
                      }}
                    />
                  </Box>
                  <Box>
                    <Typography className="label">
                      Platform Fee Discount
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      type="number"
                      placeholder="Platform Fee Discount"
                      value={platformFeeDiscount}
                      onChange={(e: any) => {
                        if (
                          e.target.value === "" ||
                          /^[0-9.]{0,5}$/.test(e.target.value)
                        )
                          setInput({
                            ...input,
                            platformFeeDiscount: e.target.value,
                          });
                      }}
                      error={
                        platformFeeDiscount !== "" &&
                        (parseFloat(platformFeeDiscount) > 100 ||
                          parseFloat(platformFeeDiscount) < 0)
                      }
                      helperText={
                        currency === "INR"
                          ? "Enter a value between 0 and 100"
                          : undefined
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" sx={{ mr: 1 }}>
                            %
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <FormControl>
                    <Typography className="label">
                      Settlement Frequency
                    </Typography>
                    <RadioGroup
                      value={settlementCycle}
                      onChange={(e) =>
                        setInput({ ...input, settlementCycle: e.target.value })
                      }
                    >
                      <FormControlLabel
                        value="BIWEEKLY"
                        control={<Radio size="small" />}
                        label="Biweekly"
                      />
                      <FormControlLabel
                        value="WEEKLY"
                        control={<Radio size="small" />}
                        label="Weekly"
                      />
                      <FormControlLabel
                        value="MONTHLY"
                        control={<Radio size="small" />}
                        label="Monthly"
                      />
                    </RadioGroup>
                  </FormControl>

                  <Box>
                    <Typography className="label">Payment Method</Typography>
                    <Box>
                      {paymentMethodsLoading ? (
                        <Typography color="text.disabled">
                          Loading...
                        </Typography>
                      ) : (
                        <>
                          {paymentMethodsData?.data?.constructor === Array &&
                          paymentMethodsData.data.length > 0 ? (
                            <RadioGroup
                              value={paymentMethodId}
                              onChange={(e) =>
                                setInput({
                                  ...input,
                                  paymentMethodId: e.target.value,
                                })
                              }
                            >
                              {paymentMethodsData.data.map(
                                (el: any, i: any) => (
                                  <FormControlLabel
                                    key={i}
                                    value={el._id}
                                    control={<Radio size="small" />}
                                    label={`${
                                      el.bankDetails.bankName
                                    } (•••• ${el.bankDetails.bankAccount.slice(
                                      -4,
                                    )})`}
                                  />
                                ),
                              )}
                            </RadioGroup>
                          ) : (
                            <Typography color="text.disabled">
                              No payment methods found
                            </Typography>
                          )}
                          <Button
                            variant="outlined"
                            size="small"
                            sx={{ mt: 1.5, textTransform: "none" }}
                            onClick={() => {
                              if (!data.owner)
                                snackbar.error("Error fetching user details");
                              else
                                setPaymentDialog({
                                  open: true,
                                  data: {
                                    _id: userId,
                                    ...data.owner,
                                  },
                                });
                            }}
                          >
                            View/Edit Payment Methods
                          </Button>
                        </>
                      )}
                    </Box>
                    <PaymentDialog
                      open={paymentDialog.open}
                      handleClose={() =>
                        setPaymentDialog({ ...paymentDialog, open: false })
                      }
                      data={paymentDialog.data}
                    />
                  </Box>
                </Box>
              </Collapse>
            </Box>
          </Box>
        )}
        {step === 1 && (
          <Box
            sx={{
              maxWidth: 560,
              mx: "auto",
            }}
          >
            <SetAvailability
              {...{
                open,
                days,
                setDays,
                twentyFourSeven,
                setTwentyFourSeven,
              }}
            />
          </Box>
        )}
        {step === 2 && (
          <Box
            sx={{
              maxWidth: 350,
              mx: "auto",
              display: "grid",
              gridTemplateColumns: { xs: "1fr", sm: "1fr" },
              gap: 3,
            }}
          >
            <Box mt={2}>
              <Box display="flex" alignItems="center">
                <Typography className="label">Auto Cutoff</Typography>
                <Tooltip title="Value should be in the range of 0 to 250 seconds">
                  <InfoOutlined
                    color="action"
                    fontSize="inherit"
                    sx={{ ml: 0.5, mt: -1.5, cursor: "pointer" }}
                  />
                </Tooltip>
              </Box>
              <TextField
                fullWidth
                size="small"
                type="number"
                value={autoCutoff}
                onChange={(e: any) => {
                  setInput({
                    ...input,
                    autoCutoff:
                      e.target.value > 250
                        ? 250
                        : e.target.value < 0
                          ? 0
                          : e.target.value,
                  });
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={{ mr: 1 }}>
                      seconds
                    </InputAdornment>
                  ),
                }}
                sx={{ mt: 1 }}
              />
            </Box>
            <Box mt={2}>
              <Box display="flex" alignItems="center">
                <Typography className="label">Power Output</Typography>
                <Tooltip title="Value should be in the range of 0 to 16 Amphere">
                  <InfoOutlined
                    color="action"
                    fontSize="inherit"
                    sx={{ ml: 0.5, mt: -1.5, cursor: "pointer" }}
                  />
                </Tooltip>
              </Box>
              <Box alignItems="center">
                <Box
                  sx={{
                    border: "1px solid #00000040",
                    width: 50,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 1,
                    mt: 1,
                  }}
                >
                  <Typography sx={{ p: 0.5 }}>{powerOutput}A</Typography>
                </Box>

                <Slider
                  sx={{ mt: 1.5 }}
                  aria-label="Temperature"
                  defaultValue={16}
                  // getAriaValueText={valuetext}
                  value={powerOutput}
                  valueLabelDisplay="auto"
                  step={1}
                  marks
                  min={0}
                  max={16}
                  onChange={(e: any) => {
                    setInput({ ...input, powerOutput: e.target.value });
                  }}
                />
              </Box>
            </Box>
          </Box>
        )}

        {step === 3 && (
          <Box
            sx={{
              maxWidth: 560,
              mx: "auto",
              "& .table": {
                borderCollapse: "collapse",
                width: 1,
                fontSize: 14,
                lineHeight: "16px",
                "& td": {
                  py: 1.25,
                  px: 2,
                },
                "& .bold": {
                  fontWeight: 500,
                },
                "& .header": {
                  px: 2,
                  py: 1,
                  position: "relative",
                  "& td": {
                    position: "absolute",
                    verticalAlign: "middle",
                    bgcolor: (theme: any) => theme.customColors.header,
                    width: 1,
                    borderRadius: "4px",
                    fontSize: 16,
                    fontWeight: 600,
                    "& span": {
                      display: "inline-block",
                      transform: "translateY(1px)",
                    },
                  },
                },
                "& .first > td": {
                  pt: 9,
                },
                "& .last > td": {
                  pb: 3,
                },
              },
            }}
          >
            <table className="table">
              <tbody>
                {[
                  { header: "Station Info", onEdit: () => setStep(0) },
                  { label: "Charger Name", value: chargerName, required: true },
                  { label: "Payment Type", value: usageType, required: true },
                  {
                    label: "Maintainer Name",
                    value: maintainerName,
                    required: true,
                  },
                  {
                    label: "Maintainer Phone",
                    value: maintainerPhone,
                    required: true,
                  },
                  {
                    label: "Plot No., Street Name, Landmark",
                    value: address,
                    required: true,
                  },
                  { label: "City", value: city, required: true },
                  { label: "Pincode", value: pincode, required: true },
                  { label: "District", value: district, required: true },
                  { label: "State", value: state, required: true },
                  { label: "Latitude", value: latitude, required: true },
                  { label: "Longitude", value: longitude, required: true },
                  { label: "Country", value: country, required: true },

                  ...(isPaid
                    ? [
                        {
                          label: "Energy Tariff",
                          value: !!ownerCostPerkWh ? `₹${ownerCostPerkWh}` : "",
                          required: true,
                          isValid: isPriceValid(ownerCostPerkWh),
                        },
                        {
                          label: "Cost Per Unit (kWh)",
                          value: !!costPerkWh ? `₹${costPerkWh}` : "",
                          required: true,
                          isValid:
                            isPriceValid(costPerkWh) &&
                            parseFloat(costPerkWh) >
                              parseFloat(ownerCostPerkWh),
                        },
                        {
                          label: "Platform Fee",
                          value: !!platformFee ? `₹${platformFee}` : "",
                          required: true,
                          isValid: isPriceValid(platformFee),
                        },
                        {
                          label: "Platform Fee Discount",
                          value: !!platformFeeDiscount
                            ? `${platformFeeDiscount}%`
                            : "",
                          required: true,
                          isValid:
                            parseFloat(platformFeeDiscount) >= 0 &&
                            parseFloat(platformFeeDiscount) <= 100,
                        },
                        ...(settlementCycle
                          ? [
                              {
                                label: "Settlement Frequency",
                                value: settlementCycle,
                                required: true,
                              },
                            ]
                          : []),
                        {
                          label: "Payment Method",
                          value:
                            paymentMethodsData?.data?.constructor === Array &&
                            paymentMethodsData.data.length > 0 &&
                            paymentMethodId &&
                            paymentMethodsData.data.find(
                              (el: any) => el._id === paymentMethodId,
                            )
                              ? paymentMethodsData.data.find(
                                  (el: any) => el._id === paymentMethodId,
                                ).bankDetails.bankName +
                                " (•••• " +
                                paymentMethodsData.data
                                  .find((el: any) => el._id === paymentMethodId)
                                  .bankDetails.bankAccount.slice(-4) +
                                ")"
                              : "",
                          required: true,
                        },
                      ]
                    : []),

                  { header: "Availability", onEdit: () => setStep(1) },
                  { label: "Sunday", value: returnTime(0) },
                  { label: "Monday", value: returnTime(1) },
                  { label: "Tuesday", value: returnTime(2) },
                  { label: "Wednesday", value: returnTime(3) },
                  { label: "Thursday", value: returnTime(4) },
                  { label: "Friday", value: returnTime(5) },
                  { label: "Saturday", value: returnTime(6) },
                  { header: "Specification", onEdit: () => setStep(2) },
                  { label: "Auto Cutoff", value: `${autoCutoff} seconds` },
                  { label: "Power Output", value: `${powerOutput} A` },
                ].map(
                  (
                    { header, onEdit, label, value, required, isValid },
                    i,
                    arr,
                  ) => {
                    const isFirst = arr[i - 1]?.header;
                    const isLast = !arr[i + 1] || arr[i + 1].header;

                    return (
                      <tr
                        key={i}
                        className={
                          header
                            ? "header"
                            : `${isFirst ? "first" : ""} ${
                                isLast ? "last" : ""
                              }`
                        }
                      >
                        {header ? (
                          <td colSpan={2}>
                            <span>{header}</span>
                            <IconButton
                              sx={{ ml: 1.5 }}
                              children={<EditOutlined />}
                              color="primary"
                              size="small"
                              onClick={onEdit}
                            />
                          </td>
                        ) : (
                          <>
                            <td>{label}</td>
                            <td className="bold">
                              {required &&
                              ["", null, undefined].includes(value) ? (
                                <Box display="flex" alignItems="center">
                                  <ErrorOutline
                                    fontSize="small"
                                    color="error"
                                    sx={{ mr: 1 }}
                                  />
                                  Required
                                </Box>
                              ) : (
                                <Box display="flex" alignItems="center">
                                  {value === "" ? "-" : value}
                                  {isValid === false && (
                                    <Box
                                      ml={2}
                                      display="flex"
                                      alignItems="center"
                                    >
                                      <WarningAmber
                                        fontSize="small"
                                        color="warning"
                                        sx={{ mr: 0.5 }}
                                      />
                                      Invalid
                                    </Box>
                                  )}
                                </Box>
                              )}
                            </td>
                          </>
                        )}
                      </tr>
                    );
                  },
                )}
              </tbody>
            </table>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {step !== 0 && (
          <Button variant="outlined" onClick={handleBack}>
            Back
          </Button>
        )}
        <Button
          onClick={handleNext}
          variant="contained"
          color={
            isComplete(step) || step === steps.length - 1
              ? "primary"
              : "inherit"
          }
          disableElevation
          disabled={step === steps.length - 1 && isDisabled()}
        >
          {step === steps.length - 1
            ? "Save"
            : isComplete(step)
              ? "Next"
              : "Skip"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const PriceInput = ({
  objKey,
  label,
  input,
  setInput,
  isPriceValid,
  currency,
  min = 1,
  max = 30,
}: any) => {
  return (
    <Box sx={{ display: "flex", alignItems: "start" }}>
      <TextField
        fullWidth
        size="small"
        type="number"
        placeholder={label}
        value={input[objKey]}
        onChange={(e: any) => {
          if (e.target.value === "" || /^[0-9.]{0,5}$/.test(e.target.value))
            setInput({
              ...input,
              [objKey]: e.target.value,
            });
        }}
        error={!!input[objKey] && !isPriceValid(input[objKey])}
        helperText={
          currency === "INR"
            ? `Enter a value between ${min} and ${max}`
            : undefined
        }
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" sx={{ ml: 1 }}>
              {getSymbolFromCurrency(currency) || "?"}
            </InputAdornment>
          ),
        }}
      />
      <IconButton
        size="small"
        color="primary"
        sx={{ ml: 0.5, mt: 0.75 }}
        onClick={() =>
          setInput({
            ...input,
            [objKey]:
              parseFloat(input[objKey]) >= min + 1
                ? String(parseFloat(input[objKey]) - 1)
                : String(min),
          })
        }
      >
        <Remove />
      </IconButton>
      <IconButton
        size="small"
        color="primary"
        sx={{ ml: 0.5, mt: 0.75 }}
        onClick={() =>
          setInput({
            ...input,
            [objKey]:
              input[objKey] === ""
                ? "1"
                : parseFloat(input[objKey]) <= max - 1
                  ? String(parseFloat(input[objKey]) + 1)
                  : String(max),
          })
        }
      >
        <Add />
      </IconButton>
    </Box>
  );
};

export default EditChargerDialog;
